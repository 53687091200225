<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h2 class="mb-0 ml-4">Novo Orçamento</h2>
              </div>
              <div class="col-4 text-right" v-if="!isInsideModal">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Voltar para lista</base-button
                >
              </div>
            </div>
            <div class="card-body"></div>
            <div class="card-body">
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom">Dados Gerais</h3>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input label="Locação">
                    <el-select
                      name="contrato"
                      ref="contratoInput"
                      :tabindex="0"
                      v-model="seguroincendio.contrato.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      @change="
                        exibedadoscontrato();
                        seguroincendio.coberturaincendio = 0;
                        calculacobertura();
                      "
                    >
                      <el-option
                        v-for="single_contrato in all_contratos"
                        :key="single_contrato.id"
                        :value="single_contrato.id"
                        :label="
                          preferencia &&
                          preferencia.contrato &&
                          single_contrato.id === preferencia.contrato.id
                            ? `★ ${single_contrato.name}`
                            : single_contrato.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.contrato" />
                  <div ref="display_contrato"></div>
                </div>

                <div class="input-group-append">
                  <base-button
                    icon
                    type="primary"
                    v-if="isModalContratoVisible == false"
                    @click="isModalContratoVisible = true"
                    style="margin: 30px 0px 25px 0px; padding: 0"
                  >
                    <span class="btn-inner--icon"
                      ><i class="ni ni-fat-add"></i
                    ></span>
                  </base-button>
                  <base-button
                    icon
                    type="danger"
                    v-if="isModalContratoVisible == true"
                    @click="isModalContratoVisible = false"
                    style="margin: 30px 0px 25px 0px; padding: 0"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-xmark"></i
                    ></span>
                  </base-button>
                </div>
              </div>
              <!--ini AddContrato-->
              <div class="mt--6 ml--4 mb-6 mr--5">
                <AddContrato
                  v-if="isModalContratoVisible == true"
                  :show.sync="isModalContratoVisible"
                  :isInsideModal="true"
                  @close-modal="isModalContratoVisible = false"
                  :insideModalOnSave="'getContrato'"
                  @callFunction="handleFunctionCall"
                ></AddContrato>
              </div>
              <!--fim AddContrato-->
              <div class="row">
                <blockquote
                  ref="display_contrato"
                  style="
                    padding: 0;
                    padding-left: 10px;
                    margin-left: 20px;
                    border-left: 3px solid #777;
                  "
                ></blockquote>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3
                  class="border-bottom"
                  v-if="seguroincendio.contrato.id !== null"
                >
                  Parâmetros do Seguro
                </h3>
              </div>
              <div class="row" v-if="seguroincendio.contrato.id !== null">
                <div class="col-md-4">
                  <base-input label="Data inicial">
                    <flat-picker
                      :tabindex="0"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        altInput: true,
                        altFormat: 'd/m/Y',
                      }"
                      class="form-control datepicker"
                      v-model="seguroincendio.datainicio"
                    >
                    </flat-picker>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.datainicio" />
                </div>
              </div>

              <div class="row" v-if="seguroincendio.contrato.id !== null">
                <div class="col-md-4">
                  <base-input label="Serviços de Assistência 24h">
                    <el-select
                      name="clausulaservico"
                      ref="clausulaservicoInput"
                      :tabindex="0"
                      v-model="seguroincendio.clausulaservico.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      @change="calculaparcela()"
                    >
                      <el-option
                        v-for="single_clausulaservico in all_clausulaservicos"
                        :key="single_clausulaservico.id"
                        :value="single_clausulaservico.id"
                        :label="
                          preferencia &&
                          preferencia.clausulaservico &&
                          single_clausulaservico.id ===
                            preferencia.clausulaservico.id
                            ? `★ ${single_clausulaservico.name}`
                            : single_clausulaservico.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.clausulaservico"
                  />
                  <div ref="display_clausulaservico"></div>
                </div>
              </div>

              <!--serviços de assitencia-->
              <card v-if="seguroincendio.contrato.id !== null">
                <div class="row">
                  <h5 class="ml-3">Serviços:</h5>
                </div>
                <div class="row">
                  <div class="col-sm" v-if="['1','2','3'].includes(seguroincendio.clausulaservico.id)"><span><small>Chaveiro</small></span></div>
                  <div class="col-sm" v-if="['1','2','3'].includes(seguroincendio.clausulaservico.id)"><span><small>Reparo hidráulico</small></span></div>
                </div>
                <div class="row">
                  <div class="col-sm" v-if="['2','3'].includes(seguroincendio.clausulaservico.id)"><span><small>Reparo Elétrico</small></span></div>
                  <div class="col-sm" v-if="['2','3'].includes(seguroincendio.clausulaservico.id)"><span><small>Help desk para smartphones, notebooks e tablets</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Desentupimento</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Reparos em linha branca</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Reparos em ar-condicionado</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Substituição de telhas e cumeeiras</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Assistência à bike</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Assistência em antenas</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Instalação e reinstalação</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Instalação de kit de fixação</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Limpeza e desentupimento de calhas e dutos</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Ventilador de teto</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Central telefônica, interfone e porteiro eletrônico</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Conectividade</small></span></div>
                  </div>
                <div class="row">
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Mudança de mobiliário</small></span></div>
                  <div class="col-sm" v-if="['2'].includes(seguroincendio.clausulaservico.id)"><span><small>Reversão de gás para fogão</small></span></div>
                </div>

                
                <div class="row">
                  <div class="col-sm mt-4" ><span><small><i>*Consulte Detalhes e Limites nas condições gerais do produto.</i></small></span></div>
                </div>
              </card>
              <!--fim serviços de assitencia-->

              <div class="row" v-if="seguroincendio.contrato.id !== null">
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="valorlocacaoInput"
                    label="Valor do aluguel"
                    prepend-icon="fa fa-square-o"
                    v-model="valorlocacaoFormatado"
                    @change="
                      seguroincendio.coberturaincendio = 0;
                      calculacobertura();
                      calculaparcela();
                    "
                  />

                  <validation-error
                    :errors="apiValidationErrors.valorlocacao"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="areaconstruidaInput"
                    label="Área Construida"
                    prepend-icon="fa fa-square-o"
                    v-model="areaconstruidaFormatado"
                    @change="
                      seguroincendio.coberturaincendio = 0;
                      calculacobertura();
                      calculaparcela();
                    "
                  />

                  <validation-error
                    :errors="apiValidationErrors.areaconstruida"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    label="Método de cálculo das Coberturas"
                    :disabled="
                      seguroincendio.valorlocacao < 400 ||
                      seguroincendio.areaconstruida < 40 ||
                      seguroincendio.contrato.id < 0
                    "
                    v-if="seguroincendio.areaconstruida > 0"
                  >
                    <el-select
                      name="metodocalculo"
                      ref="metodocalculoInput"
                      :tabindex="0"
                      v-model="seguroincendio.metodocalculo.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      @change="
                        seguroincendio.coberturaincendio = 0;
                        calculacobertura();
                        calculaparcela();
                      "
                      :disabled="
                        seguroincendio.valorlocacao < 400 ||
                        seguroincendio.areaconstruida < 40 ||
                        seguroincendio.contrato.id < 0
                      "
                      v-if="seguroincendio.areaconstruida > 0"
                    >
                      <el-option
                        v-for="single_metodocalculo in all_metodocalculos_filtrado"
                        :key="single_metodocalculo.id"
                        :value="single_metodocalculo.id"
                        :label="
                          preferencia &&
                          preferencia.metodocalculo &&
                          single_metodocalculo.id ===
                            preferencia.metodocalculo.id
                            ? `★ ${single_metodocalculo.name}`
                            : single_metodocalculo.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.metodocalculo"
                  />
                  <div ref="display_metodocalculo"></div>
                </div>
              </div>

              <div class="row" v-if="seguroincendio.contrato.id !== null">
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="descontopercentualInput"
                    label="Percentual de Desconto"
                    prepend-icon="ni ni-user-run"
                    v-model="descontopercentualFormatado"
                    @change="calculaparcela()"
                    v-if="seguroincendio.areaconstruida > 0"
                    :readonly="descontopercentualMin == descontopercentualMax"
                  />
                  
                  <div v-if="descontopercentualMin != descontopercentualMax">
                    <base-slider
                      v-model="seguroincendio.descontopercentual"
                      :key="`slider-${descontopercentualMin}-${descontopercentualMax}`"
                      :range="{
                        min: descontopercentualMin,
                        max: descontopercentualMax,
                      }"
                      v-if="seguroincendio.areaconstruida > 0"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.descontopercentual"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    label="Numero de Parcelas"
                    v-if="seguroincendio.areaconstruida > 0"
                  >
                    <el-select
                      name="seguroiprevnumeroparcela"
                      ref="seguroiprevnumeroparcelaInput"
                      :tabindex="0"
                      v-model="seguroincendio.seguroiprevnumeroparcela.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      v-if="seguroincendio.areaconstruida > 0"
                    >
                      <el-option
                        v-for="single_seguroiprevnumeroparcela in all_seguroiprevnumeroparcelas"
                        :key="single_seguroiprevnumeroparcela.id"
                        :value="single_seguroiprevnumeroparcela.id"
                        :label="
                          preferencia &&
                          preferencia.seguroiprevnumeroparcela &&
                          single_seguroiprevnumeroparcela.id ===
                            preferencia.seguroiprevnumeroparcela.id
                            ? `★ ${single_seguroiprevnumeroparcela.name}`
                            : single_seguroiprevnumeroparcela.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.seguroiprevnumeroparcela"
                  />
                  <div ref="display_seguroiprevnumeroparcela"></div>
                </div>

                <div class="col-md-4">
                  <base-input
                    label="Atividade Econômica"
                    v-if="seguroincendio.areaconstruida > 0 && requireatividadeeconomica == 1"
                  >
                    <el-select
                      name="pessoaae"
                      ref="pessoaaeInput"
                      :tabindex="0"
                      v-model="seguroincendio.pessoaae.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      v-if="seguroincendio.areaconstruida > 0"
                    >
                      <el-option
                        v-for="single_pessoaae in sortedPessoaaes"
                        :key="single_pessoaae.id"
                        :value="single_pessoaae.id"
                        :label="single_pessoaae.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.seguroiprevnumeroparcela"
                  />
                  <div ref="display_seguroiprevnumeroparcela"></div>
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3
                  class="border-bottom"
                  v-if="
                    seguroincendio.clausulaservico.id !== null &&
                    seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                  "
                >
                  Coberturas
                </h3>
              </div>
              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              ></div>

              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturaincendioInput"
                    label="Incêndio"
                    prepend-icon="fa fa-fire"
                    v-model="coberturaincendioFormatado"
                    @focus="coberturaincendioFocus=true"
                    @blur="coberturaincendioFocus=false;calculacobertura(1)"
                    @change="calculacobertura(1)"
                  />

                  <div v-if="coberturaincendioMin != coberturaincendioMax">
                    <base-slider
                      v-model="seguroincendio.coberturaincendio"
                      :key="`slider-${coberturaincendioMin}-${coberturaincendioMax}`"
                      :range="{
                        min: coberturaincendioMin,
                        max: coberturaincendioMax,
                      }"
                      :step="5000"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturaincendio"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturacivilInput"
                    label="Responsabilidade Civil"
                    prepend-icon="fa fa-users"
                    v-model="coberturacivilFormatado"
                    :placeholder="getPlaceholder(coberturacivilFormatado)"
                    @change="calculaparcela()"
                  />

                  <div v-if="coberturacivilMin != coberturacivilMax">
                    <base-slider
                      v-model="seguroincendio.coberturacivil"
                      :key="`slider-${coberturacivilMin}-${coberturacivilMax}`"
                      :range="{ min: coberturacivilMin, max: coberturacivilMax }"
                      :step="500"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturacivil"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturaconteudoInput"
                    label="Conteúdo"
                    prepend-icon="ni ni-app"
                    v-model="coberturaconteudoFormatado"
                    @change="calculaparcela()"
                    :readonly="coberturaconteudoMin == coberturaconteudoMax"
                  />

                  <div v-if="coberturaconteudoMin != coberturaconteudoMax">
                    <base-slider
                      v-model="seguroincendio.coberturaconteudo"
                      :key="`slider-${coberturaconteudoMin}-${coberturaconteudoMax}`"
                      :range="{
                        min: coberturaconteudoMin,
                        max: coberturaconteudoMax,
                      }"
                      :readonly="coberturaconteudoMin == coberturaconteudoMax"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturaconteudo"
                  />
                </div>
              </div>

              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturaeletricoInput"
                    label="Danos Elétricos"
                    prepend-icon="fa fa-bolt"
                    v-model="coberturaeletricoFormatado"
                    :placeholder="getPlaceholder(coberturaeletricoFormatado)"
                    @change="calculaparcela()"
                  />

                  <div v-if="coberturaeletricoMin != coberturaeletricoMax">
                    <base-slider
                      v-model="seguroincendio.coberturaeletrico"
                      :key="`slider-${coberturaeletricoMin}-${coberturaeletricoMax}`"
                      :range="{
                        min: coberturaeletricoMin,
                        max: coberturaeletricoMax,
                      }"
                      :step="500"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturaeletrico"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturaimpactoInput"
                    label="Impacto Veículos"
                    prepend-icon="fa fa-car-burst"
                    v-model="coberturaimpactoFormatado"
                    :placeholder="getPlaceholder(coberturaimpactoFormatado)"
                    @change="calculaparcela()"
                  />

                  <div v-if="coberturaimpactoMin != coberturaimpactoMax">
                    <base-slider
                      v-model="seguroincendio.coberturaimpacto"
                      :key="`slider-${coberturaimpactoMin}-${coberturaimpactoMax}`"
                      :range="{
                        min: coberturaimpactoMin,
                        max: coberturaimpactoMax,
                      }"
                      :step="500"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturaimpacto"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturaaluguelInput"
                    label="Perda Aluguel"
                    prepend-icon="fa fa-house-user"
                    v-model="coberturaaluguelFormatado"
                    :placeholder="getPlaceholder(coberturaaluguelFormatado)"
                    @change="calculaparcela()"
                  />

                  <div v-if="coberturaaluguelMin != coberturaaluguelMax">
                    <base-slider
                      v-model="seguroincendio.coberturaaluguel"
                      :key="`slider-${coberturaaluguelMin}-${coberturaaluguelMax}`"
                      :range="{
                        min: coberturaaluguelMin,
                        max: coberturaaluguelMax,
                      }"
                      :step="100"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturaaluguel"
                  />
                </div>
              </div>

              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturavendavalInput"
                    label="Vendaval"
                    prepend-icon="fas fa-wind"
                    v-model="coberturavendavalFormatado"
                    :placeholder="getPlaceholder(coberturavendavalFormatado)"
                    @change="calculaparcela()"
                  />

                  <div v-if="coberturavendavalMin != coberturavendavalMax">
                    <base-slider
                      v-model="seguroincendio.coberturavendaval"
                      :key="`slider-${coberturavendavalMin}-${coberturavendavalMax}`"
                      :range="{
                        min: coberturavendavalMin,
                        max: coberturavendavalMax,
                      }"
                      :step="100"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturavendaval"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="coberturatumultoInput"
                    label="Tumulto"
                    prepend-icon="ni ni-user-run"
                    v-model="coberturatumultoFormatado"
                    @change="calculaparcela()"
                    v-if="coberturatumultoMax > 0"
                  />

                  <div v-if="coberturatumultoMin != coberturatumultoMax">
                    <base-slider
                      v-model="seguroincendio.coberturatumulto"
                      :key="`slider-${coberturatumultoMin}-${coberturatumultoMax}`"
                      :range="{
                        min: coberturatumultoMin,
                        max: coberturatumultoMax,
                      }"
                      v-if="coberturatumultoMax > 0"
                    ></base-slider>
                  </div>
                  <validation-error
                    :errors="apiValidationErrors.coberturatumulto"
                  />
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3
                  class="border-bottom"
                  v-if="
                    seguroincendio.clausulaservico.id !== null &&
                    seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                  "
                >
                  Contratação
                </h3>
              </div>
              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              ></div>

              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              >
                <div class="col-md-4">
                  <base-input label="Deseja Contratar automaticamente?">
                    <base-switch
                      on-text="Sim"
                      off-text="Não"
                      class="mr-1"
                      v-model="seguroincendio.autocontratacao"
                      ref="autocontratacaoInput"
                    ></base-switch>
                  </base-input>
                </div>
              </div>

              <div
                class="row"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="valorestimadoInput"
                    label="Estimativa do custo (pode sofrer alteração)"
                    prepend-icon="ni ni-user-run"
                    v-model="valorestimadoFormatado"
                    v-if="false"
                  />

                  <validation-error
                    :errors="apiValidationErrors.valorestimado"
                  />
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom"></h3>
              </div>
              <div class="row"></div>

              <div class="my-4"
                v-if="
                  seguroincendio.clausulaservico.id !== null &&
                  seguroincendio.metodocalculo.id !== null &&
                    ((requireatividadeeconomica == 1 && seguroincendio.pessoaae.id !== null) || requireatividadeeconomica == 0)
                "
              >
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Calcular
                </base-button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from "@/components/BaseSlider";
import Modal from "@/components/Modal.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from "vue-the-mask";
import axios from "axios";

import AddContrato from "@/views/app/ContratoManagement/AddContratoPage.vue";

export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    AddContrato,
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    insideModalOnSave: {
      type: String,
      required: false,
      default: null,
    },
  },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,

      isModalContratoVisible: false,
      all_contratos: [],
      all_clausulaservicos: [],
      all_metodocalculos: [],
      all_pessoaaes: [],
      all_metodocalculos_filtrado: [],
      all_seguroiprevnumeroparcelas: [
        { id: "1", type: "seguroiprevnumeroparcelas", numero: "1" },
        { id: "2", type: "seguroiprevnumeroparcelas", numero: "2" },
        { id: "3", type: "seguroiprevnumeroparcelas", numero: "3" },
        { id: "4", type: "seguroiprevnumeroparcelas", numero: "4" },
        { id: "5", type: "seguroiprevnumeroparcelas", numero: "5" },
        { id: "6", type: "seguroiprevnumeroparcelas", numero: "6" },
        { id: "7", type: "seguroiprevnumeroparcelas", numero: "7" },
        { id: "8", type: "seguroiprevnumeroparcelas", numero: "8" },
        { id: "9", type: "seguroiprevnumeroparcelas", numero: "9" },
        { id: "10", type: "seguroiprevnumeroparcelas", numero: "10" },
        { id: "11", type: "seguroiprevnumeroparcelas", numero: "11" },
      ],

      requireatividadeeconomica: 0,
      descontopercentualMin: 0,
      descontopercentualMax: 100,
      coberturaincendioMin: 0,
      coberturaincendioMax: 100,
      coberturaincendioFocus: false,
      coberturacivilMin: 0,
      coberturacivilMax: 100,
      coberturacivilFocus: false,
      coberturaconteudoMin: 0,
      coberturaconteudoMax: 100,
      coberturaconteudoFocus: false,
      coberturaeletricoMin: 0,
      coberturaeletricoMax: 100,
      coberturaeletricoFocus: false,
      coberturaimpactoMin: 0,
      coberturaimpactoMax: 100,
      coberturaimpactoFocus: false,
      coberturaaluguelMin: 0,
      coberturaaluguelMax: 100,
      coberturaaluguelFocus: false,
      coberturavendavalMin: 0,
      coberturavendavalMax: 100,
      coberturavendavalFocus: false,
      coberturatumultoMin: 0,
      coberturatumultoMax: 100,
      coberturatumultoFocus: false,

      contrato_tipo_id: 0,
      latestPinicupe: {
        CCV: 0,
        CPCEF: 0,
        CPSEM: 0,
        IGUGM: 0,
        RF: 0,
        RM: 0,
        RP: 0,
        RPCEF: 0,
        RPCEM: 0,
        RPCEMA: 0,
        RPSEM: 0,
        RPSEP: 0,
        RSP: 0,
      },

      preferencia: {
        clausulaservicoobrigatorio: 0,
        descontopercentualmin: 0,
        descontopercentualmax: 0,
        descontopercentualpad: 0,
        seguroiprevnumeroparcelaobrigatorio: 0,
        autocontratacao: 0,
        autocontratacaoobrigatorio: 0,
      },

      contratos_filter_itens: {
        seguroistatu_id: [1, 5, 6, 13, 17, 7, 10],
        id: null,
      },
      seguroincendio: {
        type: "seguroincendios",

        datainicio: "",
        valorlocacao: "",
        areaconstruida: "",
        descontopercentual: "",
        coberturaincendio: "",
        coberturacivil: "",
        coberturaconteudo: "",
        coberturaeletrico: "",
        coberturaimpacto: "",
        coberturaaluguel: "",
        coberturavendaval: "",
        coberturatumulto: "",
        autocontratacao: "",
        valorestimado: "",

        pessoaae: {
          type: "pessoaaes",
          id: null,
        },
        contrato: {
          type: "contratos",
          id: null,
        },
        clausulaservico: {
          type: "clausulaservicos",
          id: null,
        },
        metodocalculo: {
          type: "metodocalculos",
          id: null,
        },
        seguroiprevnumeroparcela: {
          type: "seguroiprevnumeroparcelas",
          id: null,
        },
        relationshipNames: [
          "contrato",
          "clausulaservico",
          "metodocalculo",
          "seguroiprevnumeroparcela",
          "pessoaae"
        ],
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["contrato","clausulaservico","metodocalculo","seguroiprevnumeroparcela"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    sortedPessoaaes() {
          const favoritos = this.all_pessoaaes.filter(p => p.favorito);
          const naoFavoritos = this.all_pessoaaes.filter(p => !p.favorito);
          return favoritos.concat(naoFavoritos);
    },
    valorlocacaoFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.valorlocacao);
      },
      set(valor) {
        this.seguroincendio.valorlocacao = this.numToUs(valor);
      },
    },
    areaconstruidaFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.areaconstruida);
      },
      set(valor) {
        this.seguroincendio.areaconstruida = this.numToUs(valor);
      },
    },
    descontopercentualFormatado: {
      get() {
        return this.numToInt(this.seguroincendio.descontopercentual);
      },
      set(valor) {
        this.seguroincendio.descontopercentual = this.numToInt(valor);
      },
    },
    coberturaincendioFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturaincendio);
      },
      set(valor) {
        this.seguroincendio.coberturaincendio = this.numToUs(valor);
      },
    },
    coberturacivilFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturacivil);
      },
      set(valor) {
        this.seguroincendio.coberturacivil = this.numToUs(valor);
      },
    },
    coberturaconteudoFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturaconteudo);
      },
      set(valor) {
        this.seguroincendio.coberturaconteudo = this.numToUs(valor);
      },
    },
    coberturaeletricoFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturaeletrico);
      },
      set(valor) {
        this.seguroincendio.coberturaeletrico = this.numToUs(valor);
      },
    },
    coberturaimpactoFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturaimpacto);
      },
      set(valor) {
        this.seguroincendio.coberturaimpacto = this.numToUs(valor);
      },
    },
    coberturaaluguelFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturaaluguel);
      },
      set(valor) {
        this.seguroincendio.coberturaaluguel = this.numToUs(valor);
      },
    },
    coberturavendavalFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturavendaval);
      },
      set(valor) {
        this.seguroincendio.coberturavendaval = this.numToUs(valor);
      },
    },
    coberturatumultoFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.coberturatumulto);
      },
      set(valor) {
        this.seguroincendio.coberturatumulto = this.numToUs(valor);
      },
    },
    valorestimadoFormatado: {
      get() {
        return this.numToBr(this.seguroincendio.valorestimado);
      },
      set(valor) {
        this.seguroincendio.valorestimado = this.numToUs(valor);
      },
    },
  },
  watch: {
    "seguroincendio.descontopercentual": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturaincendio": function (newValue, oldValue) {
      this.calculacobertura(1);
    },
    "seguroincendio.coberturacivil": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturaconteudo": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturaeletrico": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturaimpacto": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturaaluguel": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturavendaval": function (newValue, oldValue) {
      this.calculaparcela();
    },
    "seguroincendio.coberturatumulto": function (newValue, oldValue) {
      this.calculaparcela();
    },
  },
  created() {
    this.getPreferencias();
    this.getContrato();
    this.getClausulaservico();
    this.getMetodocalculo();
    this.getPessoaAe();    
    this.getSeguroiprevnumeroparcela();
  },

  methods: {
    getPlaceholder(value) {
      return value === null ? 'Para contratar, insira um valor' : '';
    },

    async getContrato(idn = null) {
      try {
        if (this.$route && this.$route.params && this.$route.params.contrato) {
          this.contratos_filter_itens.id = this.$route.params.contrato;
          if (idn == null && "contratos" == "contratos") {
            idn = this.$route.params.contrato;
          }
        } else {
          delete this.contratos_filter_itens.id;
        }

        let params = {
          filter: {
            ...(this.contratos_filter_itens ? this.contratos_filter_itens : {}),
          },
        };
        //if(this.all_contratos || Object.keys(this.all_contratos).lenght == undefined){
        await this.$store.dispatch("contratos/list", params);
        this.all_contratos = await this.$store.getters["contratos/dropdown"];
        //}

        if (this.all_contratos && this.all_contratos.length) {
          // this.seguroincendio.contrato.id = this.all_contratos[0].id;
          if (idn !== null) {
            this.seguroincendio.contrato.id = idn;
            this.$refs.contratoInput.$emit(
              "change",
              this.seguroincendio.contrato.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getClausulaservico(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.clausulaservicos_filter_itens
              ? this.clausulaservicos_filter_itens
              : {}),
          },
        };
        //if(this.all_clausulaservicos || Object.keys(this.all_clausulaservicos).lenght == undefined){
        await this.$store.dispatch("clausulaservicos/list", params);
        this.all_clausulaservicos = await this.$store.getters[
          "clausulaservicos/dropdown"
        ];
        //}

        if (this.all_clausulaservicos && this.all_clausulaservicos.length) {
          // this.seguroincendio.clausulaservico.id = this.all_clausulaservicos[0].id;
          if (idn !== null) {
            this.seguroincendio.clausulaservico.id = idn;
            this.$refs.clausulaservicoInput.$emit(
              "change",
              this.seguroincendio.clausulaservico.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getMetodocalculo(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.metodocalculos_filter_itens
              ? this.metodocalculos_filter_itens
              : {}),
          },
        };
        //if(this.all_metodocalculos || Object.keys(this.all_metodocalculos).lenght == undefined){
        await this.$store.dispatch("metodocalculos/list", params);
        this.all_metodocalculos = await this.$store.getters[
          "metodocalculos/dropdown"
        ];
        //}

        if (this.all_metodocalculos && this.all_metodocalculos.length) {
          // this.seguroincendio.metodocalculo.id = this.all_metodocalculos[0].id;
          if (idn !== null) {
            this.seguroincendio.metodocalculo.id = idn;
            this.$refs.metodocalculoInput.$emit(
              "change",
              this.seguroincendio.metodocalculo.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getPessoaAe(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...({}),
          },
        };
        
        await this.$store.dispatch("pessoaaes/list", params);
        this.all_pessoaaes = await this.$store.getters[
          "pessoaaes/dropdown"
        ];
        //}

        if (this.all_pessoaaes && this.all_pessoaaes.length) {
          // this.seguroincendio.metodocalculo.id = this.all_metodocalculos[0].id;
          if (idn !== null) {
            this.seguroincendio.pessoaae.id = idn;
            this.$refs.pessoaaeInput.$emit(
              "change",
              this.seguroincendio.pessoaae.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getSeguroiprevnumeroparcela(idn = null) {
      /*
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.seguroiprevnumeroparcelas_filter_itens
              ? this.seguroiprevnumeroparcelas_filter_itens
              : {}),
          },
        };
        //if(this.all_seguroiprevnumeroparcelas || Object.keys(this.all_seguroiprevnumeroparcelas).lenght == undefined){
        await this.$store.dispatch("seguroiprevnumeroparcelas/list", params);
        this.all_seguroiprevnumeroparcelas = await this.$store.getters[
          "seguroiprevnumeroparcelas/dropdown"
        ];
        //}

        if (
          this.all_seguroiprevnumeroparcelas &&
          this.all_seguroiprevnumeroparcelas.length
        ) {
          // this.seguroincendio.seguroiprevnumeroparcela.id = this.all_seguroiprevnumeroparcelas[0].id;
          if (idn !== null) {
            this.seguroincendio.seguroiprevnumeroparcela.id = idn;
            this.$refs.seguroiprevnumeroparcelaInput.$emit(
              "change",
              this.seguroincendio.seguroiprevnumeroparcela.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
      */
    },

    async calculacobertura(op = null) {
      //alert(JSON.stringify(this.all_metodocalculos));
      //alert(JSON.stringify(this.seguroincendio.metodocalculo.id));
      var formula_at;
      var area_at = this.seguroincendio.areaconstruida;
      var aluguel_at = this.seguroincendio.valorlocacao;
      var val_incendio = this.seguroincendio.coberturaincendio;
      var valor_calculado = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      //não deixa selecionar metodo de calculo diferente do manual se nao preencher area e valor do aluguel
      if (
        (area_at < 40 || aluguel_at < 400) &&
        this.seguroincendio.metodocalculo.id != 1
      ) {
        this.seguroincendio.metodocalculo.id = "1";
      }

      for (const [key, metodo_value] of Object.entries(
        this.all_metodocalculos
      )) {
        if (this.seguroincendio.metodocalculo.id == metodo_value.id) {
          //campo selecionado
          //alert(JSON.stringify(metodo_value));
          var formulas = [
            "incendio",
            "civil",
            "conteudo",
            "eletrico",
            "impacto",
            "aluguel",
            "vendaval",
            "tumulto",
          ];

          //define valores de cada campo
          for (var i = 0; i < formulas.length; i++) {
            formula_at = eval("metodo_value.formula" + formulas[i]);
            formula_at = formula_at.toUpperCase();
            formula_at = formula_at.replaceAll("[ALUGUEL]", aluguel_at);
            formula_at = formula_at.replaceAll("[AREA]", area_at);
            formula_at = formula_at.replaceAll(",", ".");
            formula_at = formula_at.replaceAll("[INCENDIO]", val_incendio);

            formula_at = formula_at.replaceAll("[NULL]", 0);
            formula_at = formula_at.replaceAll("[CUPE_RF]", this.latestPinicupe.RF);
            formula_at = formula_at.replaceAll("[CUPE_RM]" , this.latestPinicupe.RM);
            formula_at = formula_at.replaceAll("[CUPE_RP]" , this.latestPinicupe.RP);
            formula_at = formula_at.replaceAll("[CUPE_RSP]" , this.latestPinicupe.RSP);
            formula_at = formula_at.replaceAll("[CUPE_RPCEF]" , this.latestPinicupe.RPCEF);
            formula_at = formula_at.replaceAll("[CUPE_RPCEMA]" , this.latestPinicupe.RPCEMA);
            formula_at = formula_at.replaceAll("[CUPE_RPCEM]" , this.latestPinicupe.RPCEM);
            formula_at = formula_at.replaceAll("[CUPE_RPSEM]" , this.latestPinicupe.RPSEM);
            formula_at = formula_at.replaceAll("[CUPE_RPSEP]" , this.latestPinicupe.RPSEP);
            formula_at = formula_at.replaceAll("[CUPE_RPEP]" , this.latestPinicupe.RPSEP);
            formula_at = formula_at.replaceAll("[CUPE_CPCEF]" , this.latestPinicupe.CPCEF);
            formula_at = formula_at.replaceAll("[CUPE_CPSEM]" , this.latestPinicupe.CPSEM);
            formula_at = formula_at.replaceAll("[CUPE_CCV]" , this.latestPinicupe.CCV);
            formula_at = formula_at.replaceAll("[CUPE_IGUGM]" , this.latestPinicupe.IGUGM);

            valor_calculado[i] = Math.floor(eval(formula_at + "*100")) / 100;

            if (i == 0) {
              if (formula_at != "0" && formula_at != "" && op != 1) {
                //calculo com formula
                var val_incendio = valor_calculado[i];
              } else {
                //calculo manual
                valor_calculado[i] = val_incendio;
              }
            }
          }

          //define minimos e maximos da porto
          this.coberturaincendioMin = 40000;
          this.coberturaincendioMax = 2000000;
          this.coberturaeletricoMin = 1000;
          this.coberturaeletricoMax = valor_calculado[0] * 0.5;
          this.coberturaimpactoMin = 1000;
          this.coberturaimpactoMax = valor_calculado[0] * 1;
          this.coberturaconteudoMin = valor_calculado[0] * 0.1;
          this.coberturaconteudoMax = valor_calculado[0] * 0.1;

          this.coberturavendavalMin = 1000;
          this.coberturacivilMax = valor_calculado[0] * 0.5;
          this.coberturaaluguelMax = valor_calculado[0] * 0.5;
          if (this.coberturaaluguelMax > 500000) {
            this.coberturaaluguelMax = 500000;
          }

          if (this.contrato_tipo_id <= 1) {
            //residencial ou indefinido
            this.coberturavendavalMax = valor_calculado[0] * 0.5;
            this.coberturacivilMin = 3000;
            this.coberturaaluguelMin = 0;
            this.coberturatumultoMin = 0;
            this.coberturatumultoMax = 0;
            if (this.coberturavendavalMax > 500000) {
              this.coberturavendavalMax = 500000;
            }
          } else {
            //comercial ou não informado
            this.coberturavendavalMax = valor_calculado[0] * 0.3;
            this.coberturacivilMin = 5000;
            this.coberturaaluguelMin = 1000;
            this.coberturatumultoMin = 1000;
            this.coberturatumultoMax = valor_calculado[0] * 0.5;
            if (this.coberturavendavalMax > 400000) {
              this.coberturavendavalMax = 400000;
            }
          }

          //regras de validação
          var val_max = [
            this.coberturaincendioMax,
            this.coberturacivilMax,
            this.coberturaconteudoMax,
            this.coberturaeletricoMax,
            this.coberturaimpactoMax,
            this.coberturaaluguelMax,
            this.coberturavendavalMax,
            this.coberturatumultoMax,
          ];
          var val_min = [
            this.coberturaincendioMin,
            this.coberturacivilMin,
            this.coberturaconteudoMin,
            this.coberturaeletricoMin,
            this.coberturaimpactoMin,
            this.coberturaaluguelMin,
            this.coberturavendavalMin,
            this.coberturatumultoMin,
          ];
          var val_focus = [
            this.coberturaincendioFocus,
            this.coberturacivilFocus,
            this.coberturaconteudoFocus,
            this.coberturaeletricoFocus,
            this.coberturaimpactoFocus,
            this.coberturaaluguelFocus,
            this.coberturavendavalFocus,
            this.coberturatumultoFocus,
          ];
          
          for (var ii = 0; ii < val_min.length; ii++) {
            if (valor_calculado[ii] < val_min[ii] && valor_calculado[ii]!=0 && val_focus[ii] == false) {
              valor_calculado[ii] = val_min[ii];
            }
            if (valor_calculado[ii] > val_max[ii]) {
              valor_calculado[ii] = val_max[ii];
            }
          }

          this.seguroincendio.coberturaincendio = valor_calculado[0];
          this.seguroincendio.coberturacivil = valor_calculado[1];
          this.seguroincendio.coberturaconteudo = valor_calculado[2];
          this.seguroincendio.coberturaeletrico = valor_calculado[3];
          this.seguroincendio.coberturaimpacto = valor_calculado[4];
          this.seguroincendio.coberturaaluguel = valor_calculado[5];
          this.seguroincendio.coberturavendaval = valor_calculado[6];
          this.seguroincendio.coberturatumulto = valor_calculado[7];
        }
      }

      var exe = this.calculaparcela();
    },

    async exibedadoscontrato() {
      //pega dados do inquilino selecionado
      var dados_exib = "";
      var imoveltipo_at = null;
      for (const [key, contrato_value] of Object.entries(this.all_contratos)) {
        if (this.seguroincendio.contrato.id == contrato_value.id) {
          //dados a serem exibidos
          var dados_exibir = "<table>";
          dados_exibir += "\n" + "<tr><td><b><!--imovel--></b></td></tr>";
          dados_exibir += "\n" + "<tr><td><!--proprietario--></td></tr>";
          dados_exibir +=
            "\n" + "<tr><td><!--inquilino--> <i>(Inquilino)</i></td></tr>";
          dados_exibir += "\n" + "<tr><td><!--imoveltipo--></td></tr>";
          dados_exibir +=
            "\n" +
            "<tr><td><!--tipocontrato--> - <!--situcontrato--></td></tr>";
          dados_exibir +=
            "\n" + "<tr><td>Inicio em <!--datainicio--></td></tr>";
          dados_exibir += "\n" + "<tr><td>R$ <!--valorlocacao--></td></tr>";
          dados_exibir += "</table>";

          //define valor de imoveltipo para aplicar iltro posteriormente
          var imoveltipo_at = contrato_value.imoveltipo_id;
          this.seguroincendio.valorlocacao = contrato_value.valorlocacao;
          this.contrato_tipo_id = contrato_value.tipocontrato_id;

          //preenche o campo data inicio
          var dataString = contrato_value.datainicio;
          var data = new Date(dataString);
          var hoje = new Date();
          hoje.setHours(0, 0, 0, 0);
          if (data >= hoje) {
            var dataadotada = dataString;
          } else {
            // Extraindo o ano, mês e dia
            var ano = hoje.getFullYear();
            var mes = hoje.getMonth() + 1; // getMonth() retorna um valor de 0 a 11
            var dia = hoje.getDate();
            // Garantindo que o mês e o dia tenham dois dígitos
            mes = mes < 10 ? "0" + mes : mes;
            dia = dia < 10 ? "0" + dia : dia;
            // Formatando a data como 'YYYY-MM-DD'
            var hojeFormatada = ano + "-" + mes + "-" + dia;
            var dataadotada = hojeFormatada;
          }
          this.seguroincendio.datainicio = dataadotada;

          //PRELOAD
          var dados_exibir_at = dados_exibir.replace(
            /<td>.*?<\/td>/gs,
            "<td>Carregando...</td>"
          );
          this.$refs.display_contrato.innerHTML = dados_exibir_at;

          //CARREGA DADOS DO CONTRATO
          await this.$store.dispatch(`contratos/get`, contrato_value.id);
          const contrato_selecionado =
            this.$store.getters[`contratos/contrato`];
            
          this.latestPinicupe=contrato_selecionado.imovel.municipio.latestPinicupe;

          ///em caso de PJ atividade economica é obrigatório
          if(contrato_selecionado.inquilino.tipopessoa.id == 2){
            this.requireatividadeeconomica=1;
          }else{
            this.seguroincendio.pessoaae.id=null;
            this.requireatividadeeconomica=0;
          }
          
          //variaveis personalizadas
          var ex_imovel_endereco = contrato_selecionado.imovel.codimovel + ": ";
          ex_imovel_endereco += contrato_selecionado.imovel.logradouro;
          ex_imovel_endereco += ", " + contrato_selecionado.imovel.numero;
          if (contrato_selecionado.imovel.complemento != null) {
            ex_imovel_endereco +=
              " - " + contrato_selecionado.imovel.complemento;
          }
          ex_imovel_endereco += " - " + contrato_selecionado.imovel.bairro;

          var ex_proprietarios = "";
          contrato_selecionado.imovel.proprietarios.forEach((element) => {
            ex_proprietarios +=
              "<div>" + element.nome + " <i>(Proprietário)</i></div>";
          });

          var ex_data_inicio = contrato_selecionado.datainicio
            .split("-")
            .reverse()
            .join("/");

          //substituições
          var dados_exibir_at = dados_exibir;
          dados_exibir_at = dados_exibir_at.replace(
            "<!--imoveltipo-->",
            contrato_selecionado.imoveltipo.name
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--imovel-->",
            ex_imovel_endereco
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--tipocontrato-->",
            contrato_selecionado.tipocontrato.name
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--situcontrato-->",
            contrato_selecionado.situcontrato.name
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--proprietario-->",
            ex_proprietarios
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--inquilino-->",
            contrato_selecionado.inquilino.nome
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--datainicio-->",
            ex_data_inicio
          );
          dados_exibir_at = dados_exibir_at.replace(
            "<!--valorlocacao-->",
            contrato_selecionado.valorlocacao
          );

          //insere dados
          this.$refs.display_contrato.innerHTML = dados_exibir_at;
        }
      }

      //filtra campo metodo calculo
      function filtra_metodocalculo(value) {
        if (value.imoveltipos.some(imoveltipo => imoveltipo.id === imoveltipo_at) || value.id == 1){
          return true;
        }else {
          return false;
        }
      }
      this.all_metodocalculos_filtrado = this.all_metodocalculos.filter(filtra_metodocalculo);
      this.seguroincendio.metodocalculo.id = null;
    },

    async calculaparcela() {
      var valor_seguro = 0;
      if (this.contrato_tipo_id <= 1) {
        //residencial ou indefinido
        valor_seguro +=
          this.seguroincendio.coberturaincendio *
          (0.0420933 / 100) *
          (1.07 * 1.3 * 1.84339262253707);
        valor_seguro +=
          this.seguroincendio.coberturacivil *
          (0.02106 / 100) *
          (1.07 * 1.3 * 1.84339262253707);
        valor_seguro += this.seguroincendio.coberturaconteudo * 0;
        valor_seguro +=
          this.seguroincendio.coberturaeletrico *
          (0.1941111 / 100) *
          (1.07 * 1.3 * 1.84339262253707);
        valor_seguro +=
          this.seguroincendio.coberturaimpacto *
          (0.0418367 / 100) *
          (1.07 * 1.3 * 1.84339262253707);
        valor_seguro +=
          this.seguroincendio.coberturaaluguel *
          (0.0269333 / 100) *
          (1.07 * 1.3 * 1.84339262253707);
        valor_seguro +=
          this.seguroincendio.coberturavendaval *
          (0.3331428 / 100) *
          (1.07 * 1.3 * 1.84339262253707);
        valor_seguro += this.seguroincendio.coberturatumulto * 0;

        //desconto
        valor_seguro =
          valor_seguro * (1 - this.seguroincendio.descontopercentual / 100);

        //serviços de assitencia:
        if (this.seguroincendio.clausulaservico.id == 2) {
          //total referenciada
          valor_seguro += 125;
        } else if (this.seguroincendio.clausulaservico.id == 3) {
          //intermediario referenciada
          valor_seguro += 30;
        }

        //imposto
        valor_seguro = valor_seguro * 1.0738;

        valor_seguro = Math.floor(valor_seguro * 100) / 100;
      } else {
        //comercial
        valor_seguro = 0;
      }

      this.seguroincendio.valorestimado = valor_seguro;

      var pat = "";
      var vat = 0;
      for (var key in this.all_seguroiprevnumeroparcelas) {
        vat =
          Math.floor(
            (valor_seguro / this.all_seguroiprevnumeroparcelas[key].id) * 100
          ) / 100;
        pat = this.all_seguroiprevnumeroparcelas[key].id + "x ";
        if (vat > 0) {
          pat += " R$ " + vat + " (Valor aproximado)";
        }
        this.all_seguroiprevnumeroparcelas[key].name = pat;
      }
    },

    async getPreferencias() {
      await this.$store.dispatch("preferencias/list");
      var preferencias = await this.$store.getters["preferencias/list"];
      this.preferencia = preferencias[0];

      this.seguroincendio.clausulaservico = {
        ...this.preferencia.clausulaservico,
      };
      this.descontopercentualMin = parseInt(
        this.preferencia.descontopercentualmin
      );
      this.descontopercentualMax = parseInt(
        this.preferencia.descontopercentualmax
      );
      this.seguroincendio.descontopercentual = parseInt(
        this.preferencia.descontopercentualpad
      );
      this.seguroincendio.seguroiprevnumeroparcela = {
        ...this.preferencia.seguroiprevnumeroparcela,
      };
      this.seguroincendio.autocontratacao = !!parseInt(
        this.preferencia.autocontratacao
      );
    },

    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
      // Verifica se o valor termina com um ponto
      let sulfix = "";
      if (valor.toString().endsWith(".")) {
        sulfix = ",";
      }

      // Encontrar a quantidade de dígitos decimais no número original
      var numDecimais = (valor.toString().split(".")[1] || "").length;
      if(numDecimais>2){var numDecimais=2;}
      let valorFormatado = Number(valor).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais,
      });

      if(valorFormatado==0){
        return null;
      }else{
        return valorFormatado.toString() + sulfix;      
      }
    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
      let valorFormatado = valorBrasileiro
        .replace(/\./g, "")
        .replace(/,/g, ".");
      // Verifica se o último caractere é uma vírgula
      return valorFormatado;
    },
    numToInt(valornormal) {
      let valorFormatado = valornormal
        .toString()
        .replace(/,/g, "");
      // Verifica se o último caractere é uma vírgula
      return Math.round(valorFormatado);
    },

    goBack() {
      this.$router.push({ name: "List Seguroincendios" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }

      //this.seguroincendio.excerpt = this.seguroincendio.description;
      /**
      this.seguroincendio.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.seguroincendio.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar = Object.assign({}, this.seguroincendio);

        if (dados_enviar.contrato?.id == null) {
          delete dados_enviar.contrato;
        }
        if (dados_enviar.clausulaservico?.id == null) {
          delete dados_enviar.clausulaservico;
        }
        if (dados_enviar.metodocalculo?.id == null) {
          delete dados_enviar.metodocalculo;
        }
        if (dados_enviar.pessoaae?.id == null) {
          delete dados_enviar.pessoaae;
        }
        if (dados_enviar.seguroiprevnumeroparcela?.id == null) {
          delete dados_enviar.seguroiprevnumeroparcela;
        }

        if (dados_enviar.created_at) {
          delete dados_enviar.created_at;
        }
        if (dados_enviar.updated_at) {
          delete dados_enviar.updated_at;
        }
        if (dados_enviar.deleted_at) {
          delete dados_enviar.deleted_at;
        }
        if (dados_enviar.tenants) {
          delete dados_enviar.tenants;
        }

        if (!this.$route.params.id) {
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("seguroincendios/add", dados_enviar);
          this.seguroincendio = await this.$store.getters[
            "seguroincendios/seguroincendio"
          ];
        } else {
          //atualizar registro
          await this.$store.dispatch("seguroincendios/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("seguroincendios/upload", {
            seguroincendio: this.seguroincendio,
            image: this.file,
          });

          this.seguroincendio.image = await this.$store.getters[
            "seguroincendios/url"
          ];

          await this.$store.dispatch(
            "seguroincendios/update",
            this.seguroincendio
          );
          this.seguroincendio = await this.$store.getters[
            "seguroincendios/Seguroincendio"
          ];
        }

        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Orçamento Adicionado com sucesso.",
        });

        if (!this.isInsideModal) {
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        } else {
          //fecha modal
          this.$emit("close-modal");
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if (this.insideModalOnSave !== null) {
            this.$emit("callFunction", {
              functionName: this.insideModalOnSave,
              args: [this.seguroincendio.id],
            });
          }
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.seguroincendio.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch(
            "seguroincendios/destroy",
            this.seguroincendio.id
          );
        }

        this.seguroincendio.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },

  mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll("span");

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute("tabindex");
    });

    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
