var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Lista de Locações")])]),_c('div',{staticClass:"col-6 text-right"},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addContrato()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-house-user"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Nova Locação")])])],1)])]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',[_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":"Pesquisar...","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('ul',{staticClass:"nav nav-tabs nav-fill"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 0 ? 'active' : '', 'nav-link'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 0;
                _vm.getList();}}},[_vm._v("Todos")])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 1 ? 'active' : '', 'nav-link', _vm.navitem_total[1]? '' : 'disabled' ],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 1;
                _vm.getList();}}},[(_vm.navitem_total[1])?_c('badge',{staticClass:"mr-2",attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[1])+" ")]):_vm._e(),_vm._v(" Orçar")],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 2 ? 'active' : '', 'nav-link', _vm.navitem_total[2]? '' : 'disabled'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 2;
                _vm.getList();}}},[(_vm.navitem_total[2])?_c('badge',{staticClass:"mr-2",attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[2])+" ")]):_vm._e(),_vm._v(" Contratar")],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 3 ? 'active' : '', 'nav-link', _vm.navitem_total[3]? '' : 'disabled'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 3;
                _vm.getList();}}},[(_vm.navitem_total[3])?_c('badge',{staticClass:"mr-2",attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[3])+" ")]):_vm._e(),_vm._v(" Renovar")],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 4 ? 'active' : '', 'nav-link', _vm.navitem_total[4]? '' : 'disabled'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 4;
                _vm.getList();}}},[(_vm.navitem_total[4])?_c('badge',{staticClass:"mr-2",attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[4])+" ")]):_vm._e(),_vm._v(" Apólice Ativa")],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 5 ? 'active' : '', 'nav-link', _vm.navitem_total[5]? '' : 'disabled'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 5;
                _vm.getList();}}},[(_vm.navitem_total[5])?_c('badge',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[5])+" ")]):_vm._e(),_vm._v(" Cancelar")],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 6 ? 'active' : '', 'nav-link', _vm.navitem_total[6]? '' : 'disabled'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 6;
                _vm.getList();}}},[(_vm.navitem_total[6])?_c('badge',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[6])+" ")]):_vm._e(),_vm._v(" Apólice Inativa")],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[_vm.navitem_at == 7 ? 'active' : '', 'nav-link', _vm.navitem_total[7]? '' : 'disabled'],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = 7;
                _vm.getList();}}},[(_vm.navitem_total[7])?_c('badge',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.navitem_total[7])+" ")]):_vm._e(),_vm._v(" FALHA")],1)])]),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.contratos},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":"Código","min-width":"90px","prop":"codigo","sortable":"custom"}}),_c('el-table-column',{attrs:{"label":"Imóvel","min-width":"200px","prop":"imovel.codimovel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('div',[_c('span',[_vm._v(_vm._s(row.imovel.codimovel)+": "+_vm._s(row.imovel.logradouro)+" nº "+_vm._s(row.imovel.numero)+" - "+_vm._s(row.imovel.bairro))])])]}}])}),_c('el-table-column',{attrs:{"label":"Seguro","min-width":"125px","prop":"seguroistatu.status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('div',[(!['2', '5', '6', '13', '17'].includes(row.seguroistatu.id))?_c('div',[_vm._v(_vm._s(row.seguroistatu.status))]):_vm._e(),(['5', '6', '13', '17'].includes(row.seguroistatu.id))?_c('div',{staticClass:"ml-0"},[_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.orcarSegIContrato(row)}}},[_c('i',{staticClass:"fas fa-house-fire"}),_vm._v("Clique para Cotar ")])],1):_vm._e(),(['2'].includes(row.seguroistatu.id))?_c('div',{staticClass:"ml-0"},[(row.seguroincendio.length==1
                          && row.seguroistatu.id == 2 
                          && !_vm.is_vencido(row.seguroincendio[0].validade) 
                          && !_vm.is_vencido(row.seguroincendio[0].datainicio))?_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.contratarSeguroincendio(row.seguroincendio[0])}}},[_c('i',{staticClass:"fas fa-handshake"}),_vm._v("Clique para Contratar ")]):_vm._e(),(row.seguroincendio.length==1
                          && ['2','17'].includes(row.seguroistatu.id) 
                          &&(_vm.is_vencido(row.seguroincendio[0].datainicio) || _vm.is_vencido(row.seguroincendio[0].validade) ))?_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.listaOrcamento(row)}}},[_c('i',{staticClass:"fas fa-handshake"}),_vm._v("Orçamento Expirado ")]):_vm._e(),(row.seguroincendio.length>1)?_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.listaOrcamento(row)}}},[_c('i',{staticClass:"fas fa-handshake"}),_vm._v("Opções de Contratação ")]):_vm._e()],1):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"min-width":"50px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[(['5', '6', '13', '17', '10'].includes(row.seguroistatu.id))?_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.orcarSegIContrato(row)}}},[_c('i',{staticClass:"fas fa-house-fire"}),_vm._v(" Cotar Seguro Incêndio ")]):_vm._e(),_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editContrato(row)}}},[_c('i',{staticClass:"fas fa-user-edit"}),_vm._v("Editar contrato")]),_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteContrato(row)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v("Remover Contrato")])])],1)]}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Exibindo de "+_vm._s(_vm.total ? _vm.from + 1 : 0)+" até "+_vm._s(_vm.to)+" de um total de "+_vm._s(_vm.total)+" registros. "),(_vm.selectedRows.length)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }